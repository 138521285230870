import Table from '@/constant/table';
import { TaskTypeText, TaskStatusText } from '@/constant/task';
import Moment from '@/utils/moment';

const { TIME_COLUMN_WIDTH } = Table;

export default [
  {
    label: '任务号',
    prop: 'taskNo',
    minWidth: 140,
  },
  {
    label: '任务类型',
    prop: 'taskType',
    minWidth: 110,
    formatter: (row) => TaskTypeText[row.taskType],
  },
  {
    label: '作业方式',
    prop: 'operationType',
    minWidth: 110,
  },
  {
    label: '任务状态',
    prop: 'taskStatus',
    formatter: (row) => TaskStatusText[row.taskStatus],
  },
  {
    label: '源容器',
    prop: 'fromContainerCode',
    minWidth: 110,
  },
  {
    label: '目标容器',
    prop: 'toContainerCodes',
    formatter: (row) => row.toContainerCodes.join(','),
    minWidth: 110,
  },
  {
    label: '任务创建时间',
    prop: 'createDateTime',
    formatter: (row) => Moment.format(row.createDateTime),
    width: TIME_COLUMN_WIDTH,
  },
  {
    label: '任务完成时间',
    prop: 'completeTime',
    formatter: (row) => Moment.format(row.completeTime),
    width: TIME_COLUMN_WIDTH,
  },
];
