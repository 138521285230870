export default {
  computed: {
    typeCodeList() {
      return this.$dictionaryFiledMap.ABSTRACT_ZONE_TYPE_CODE || [];
    },
    STOREHOUSE_TYPE_CODE() {
      return this.$dictionaryFiledMap.STOREHOUSE_TYPE_CODE || [];
    },
    ZONE_TYPE_CODE() {
      return this.$dictionaryFiledMap.ZONE_TYPE_CODE || [];
    },
    DISTRIBUTION() {
      return this.$dictionaryFiledMap.DISTRIBUTE_TYPE || [];
    },
  },
};
