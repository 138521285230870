import { wmsPlusHttp, otmsHttp } from '@/utils/http/index';

/**
 * @description: 按派车单创建波次 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/11924
 */
export function waveOrderPageLadingOrder(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/wave_order/page/lading_order', data, { params });
}
/**
 * @description: 按货主创建波次 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/11926
 */
export function waveOrderPageCustomer(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/wave_order/page/customer', data, { params });
}
/**
 * @description: 创建波次
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/11928
 */
export function waveOrderCreate(data) {
  return wmsPlusHttp.post('/warehouse_management_system/wave_order/create', data);
}

/**
 * @description: 查询站点
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/12356
 */
export function stationList(data) {
  return wmsPlusHttp.post('/warehouse_management_system/deliver_order/station/list', data);
}

/**
 * @description: 出库信息表 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/12306
 */
export function deliverOrderPage(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/deliver_order/page', data, {
    params,
  });
}
/**
 * @description: 出库信息表 根据ID获取
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/12316
 */
export function deliverOrderDetail(data) {
  return wmsPlusHttp.post('/warehouse_management_system/deliver_order/detail', data);
}
/**
 * @description: 状出库单取消
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/12314
 *
 */
export function deliverOrderCancel(data) {
  return wmsPlusHttp.post('/warehouse_management_system/deliver_order/cancel', data);
}
/**
 * @description: 更新提货单信息验证 上传Excel
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/12312
 *
 */
export function deliverOrderLadingImportCheck(data) {
  return wmsPlusHttp.post('/warehouse_management_system/deliver_order/lading/import/check', data);
}
/**
 * @description: 更新提货单信息
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/12310
 *
 */
export function deliverOrderLadingImport(data) {
  return wmsPlusHttp.post('/warehouse_management_system/deliver_order/lading/import', data);
}
/**
 * @description: 新增出库单：W侧调用
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/12384
 *
 */
export function deliverOrderSave(data) {
  return wmsPlusHttp.post('/warehouse_management_system/deliver_order/save', data);
}

/**
 * @description: 指定批属性
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/16129
 *
 */
export function batchAttribute(data) {
  return wmsPlusHttp.post('/warehouse_management_system/batch_attribute/appoint', data);
}
/**
 * @description: 指定库位 分组聚合查询库存可用数量
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/16495
 *
 */
export function querySumGoodsAmount(data) {
  return wmsPlusHttp.post('/warehouse_management_system/stock/query_sum_goods_amount', data);
}
/**
 * @description: 货主货品的分页查询-W侧数据
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/12438
 *
 */
export function loadGoodsByWms(params, data) {
  return wmsPlusHttp.post('/warehouse_foundation/warehouse_goods/page_from_wms', data, { params });
}
/**
 * @description: 上传附件
 * @api文档：https://yapi.ops.yunlizhi.cn/project/250/interface/api/5505
 *
 */
export function uploadFile(data) {
  return wmsPlusHttp.post('/bfs_oss/upload', data);
}
/**
 * @description: 下载提货单模板
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/12508
 *
 */
export function downLoadTemplate(data, fileName) {
  return wmsPlusHttp.download('/warehouse_management_system/file/template/download', data, { fileName, extension: 'xlsx' });
}
/**
 * @description: 二维导入出库单验证
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/12644
 *
 */
export function importTwoDimensionalCheck(data) {
  return wmsPlusHttp.post('/warehouse_management_system/deliver_order/import/two_dimensional/check', data);
}
/**
 * @description: 一维导入出库单验证,基础数据校验
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/12646
 *
 */
export function importOneDimensionalCheck(data) {
  return wmsPlusHttp.post('/warehouse_management_system/deliver_order/import/one_dimensional/check/base_data', data);
}

/**
 * @description: 批次字段列表
 * @api文档：https://yapi.ops.yunlizhi.cn/project/385/interface/api/12622
 *
 */
export function batchFieldList(params) {
  return wmsPlusHttp.get('/warehouse_management_system/rule_batch_attribute/get', {}, { params });
}
/**
 * @description: 批出库信息表 批量导入出库单信息
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/12318
 *
 */
export function batchImport(data) {
  return wmsPlusHttp.post('/warehouse_management_system/deliver_order/batch/import', data, {});
}
/**
 * @description: 编辑出库单
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/14276
 *
 */
export function deliverOrderEdit(data) {
  return wmsPlusHttp.post('/warehouse_management_system/deliver_order/edit', data, {});
}
/**
 * @description: export
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/14298
 *
 */
export function deliverOrderExport(data, config) {
  return wmsPlusHttp.download('/warehouse_management_system/deliver_order/export', data, config);
}
/**
 * @description: 通过仓库号以及出库类型匹配规则
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/14543
 *
 */
export function deliverFlowMatchOne(data) {
  return wmsPlusHttp.post('/warehouse_management_system/rule_deliver_flow/match_one', data);
}
/**
 * @description: 保存时校验库存是否足够
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/17751
 *
 */
export function checkDeliverOrderBeforeSave(data) {
  return wmsPlusHttp.post('/warehouse_management_system/deliver_order/save/check', data);
}
/**
 * @description: 编辑时校验库存是否足够
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/21341
 *
 */
export function checkDeliverOrderBeforeEdit(data) {
  return wmsPlusHttp.post('/warehouse_management_system/deliver_order/edit/check', data);
}
/**
 * @description: 一维导入出库单验证,库存数据
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/17773
 *
 */
export function checkOneDimensionalImportDeliverOrderStock(data) {
  return wmsPlusHttp.post('/warehouse_management_system/deliver_order/import/one_dimensional/check/stock_data', data);
}
/**
 * @description: 基础信息校验错误信息导出
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/19179
 *
 */
export function checkErrorExport(data, config) {
  return wmsPlusHttp.download('/warehouse_management_system/deliver_order/check_base_data/error/export', data, config);
}
/**
 * @description: 库存校验错误信息导出
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/19181
 *
 */
export function stockErrorExport(data, config) {
  return wmsPlusHttp.download('/warehouse_management_system/deliver_order/check_stock_data/error/export', data, config);
}
/**
 * 城市数据集
 * https://yapi.ops.yunlizhi.cn/project/130/interface/api/15191
 */
export function getCityAreaTree(data) {
  return otmsHttp.post('base_plus/city_area/tree', data);
}

/**
 * @description: 默认库位
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/21041
 *
 */
export function defaultLocation(data) {
  return wmsPlusHttp.post('/warehouse_management_system/deliver_order/default/difference_location/get', data);
}
/**
 * @description: 确认拦截
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/21043
 *
 */
export function confirmIntercept(data) {
  return wmsPlusHttp.post('/warehouse_management_system/deliver_order/difference_move/execute', data);
}
/**
 * @description: 库位查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/12022
 *
 */
export function locationQuery(data) {
  return wmsPlusHttp.post('/warehouse_foundation/storehouse/page', data);
}
/**
 * 波次分组方式
 * https://yapi.ops.yunlizhi.cn/project/382/interface/api/21139
 */
export function waveGroupTypes() {
  return wmsPlusHttp.get('/warehouse_management_system/wave_order/wave_group_types');
}
/**
 * @description: 自定分组波次创建
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/21141
 *
 */
export function customGroupCreate(data) {
  return wmsPlusHttp.post('/warehouse_management_system/wave_order/custom_group/create', data);
}
/**
 * @description: 获取固定线路
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/21215
 *
 */
export function deliverOrderFillLine(data) {
  return wmsPlusHttp.post('/warehouse_management_system/deliver_order/fill_line', data);
}
/**
 * @description: 批量设置 出库单加急/ 取消加急
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/21277
 */
export function deliverOrderUrgent(data) {
  return wmsPlusHttp.post('/warehouse_management_system/deliver_order/urgent', data);
}
