<template>
  <PlTable
    :show-overflow-tooltip="true"
    :data="tasks"
    border
    :columns="tableColumns"
  />
</template>
<script>
import loadingMixin from '@thales/loading';

import tableColumns from './fileds';

// TODO: 获取数据
export default {
  mixins: [loadingMixin],
  props: {
    tasks: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tableColumns,
      tableData: [],
      loading: {
        getTableData: false,
      },
    };
  },
  methods: {
    getTableData() {
      this.tableData = [];
    },
  },
};
</script>
<style scoped>
.width-100 {
  width: 100%;
}
</style>
