import moment, { Moment } from 'moment';
import { YMDHMS } from '@/constant/timeFormat';
import { getToken } from '@/utils/auth';
import env from '../../../../env/index';
import { FILE_TYPE_ENUM } from './constant';

export const timeFormat = (time) => (time ? moment(time).format(YMDHMS) : '-');

export const momentToTimestame = (params) => {
  const paramsKeys = Object.keys(params);
  const newParams = params;
  paramsKeys.forEach((item) => {
    if (newParams[item] instanceof Moment) {
      newParams[item] = params[item].valueOf();
    }
  });
  return newParams;
};

export const getFileNameByFileUrl = (url = '', suffix = false) => {
  const file = url.split('/');
  const fileExt = file[file.length - 1] ? file[file.length - 1] : '';
  const fileNoExt = fileExt.split('.');
  return !suffix ? fileNoExt[0] : fileExt;
};
export const getFileSuffixByFileUrl = (url = '') => {
  const file = url.split('/');
  const fileExt = file[file.length - 1] ? file[file.length - 1] : '';
  const fileNoExt = fileExt.split('.');
  return fileNoExt[1];
};
// 文件下载方法
export const downloadFile = (url, form) => {
  const http = new XMLHttpRequest();
  const fromDate = {
    ...form,
  };
  http.open('POST', `${env.WMS_WEB_PLUS}${url}`);
  http.responseType = 'blob';
  http.setRequestHeader('Authorization', getToken());
  http.setRequestHeader('Content-Type', 'application/json');
  http.onreadystatechange = () => {
    if (http.status === 200 && http.readyState === 4) {
      const filename = getFileNameByFileUrl(form.path);
      const blob = new Blob([http.response], { type: FILE_TYPE_ENUM[`.${getFileSuffixByFileUrl(form.path)}`] });
      const csvUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = csvUrl;
      link.download = filename;
      link.click();
    }
  };
  http.send(JSON.stringify(fromDate));
};
