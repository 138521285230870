import unitNumber from '@/utils/unitNumber';
import { CROSS_DOCKING_ENUM } from '@/constant/crossDocking';
import { formatKeyValueObject } from '@/utils/base';
import { TEMPERATURE_LAYER_CODE_NEW_ENUM } from '../constant';

export default function TABLECOLUMN(GOODS_QUALITY) {
  const GOODS_QUALITY_ENUM = formatKeyValueObject(GOODS_QUALITY);
  return [{
    label: '货主货品编号',
    prop: 'shipperGoodsCode',
    minWidth: 110,
  }, {
    label: '商品名称',
    prop: 'goodsName',
  }, {
    label: '温层',
    prop: 'temperatureLayer',
    formatter: ({ temperatureLayer }) => TEMPERATURE_LAYER_CODE_NEW_ENUM[temperatureLayer],
  }, {
    label: '指定发货批次',
    prop: 'batchNo',
    minWidth: 120,
  }, {
    label: '指定库位',
    prop: 'storehouseCode',
    minWidth: 120,
  }, {
    label: '货品质量',
    prop: 'quality',
    formatter: ({ quality }) => GOODS_QUALITY_ENUM[quality],
  }, {
    label: '包装规格',
    prop: 'spec',
  }, {
    label: '预约数量',
    prop: 'bookingNum',
    formatter: (rowData) => (rowData.bookingNum ? unitNumber.calculate({
      ...rowData, smallUnitNumber: rowData.bookingNum,
    }).txt : ''),
  }, {
    label: '出库量',
    prop: 'deliverNum',
    formatter: (rowData) => (rowData.deliverNum ? unitNumber.calculate({
      ...rowData, smallUnitNumber: rowData.deliverNum,
    }).txt : ''),
  }, {
    label: '是否越库',
    prop: 'crossDocking',
    formatter: ({ crossDocking }) => CROSS_DOCKING_ENUM[crossDocking],
  }, {
    label: '已分配量',
    prop: 'allocateAmountStr',
  }, {
    label: '已装车量',
    prop: 'loadingAmountStr',
  }, {
    label: '预约辅助单位数量',
    prop: 'bookingAuxiliaryAmount',
    formatter: (row) => (row.bookingAuxiliaryAmount ? row.bookingAuxiliaryAmount + row.auxiliaryUnit : ''),
  }, {
    label: '分配辅助单位数量',
    prop: 'allocateAuxiliaryAmount',
    formatter: (row) => (row.allocateAuxiliaryAmount ? row.allocateAuxiliaryAmount + row.auxiliaryUnit : ''),
  }, {
    label: '实发辅助单位数量',
    prop: 'shippedAuxiliaryAmount',
    formatter: (row) => (row.shippedAuxiliaryAmount ? row.shippedAuxiliaryAmount + row.auxiliaryUnit : ''),
  }];
}
